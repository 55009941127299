import { notifyError, notifySuccess } from '@brasshq/ui-kit';
import { captureException as SentryCaptureException } from '@sentry/react';
import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import {
  differenceInHours,
  formatDistanceToNowStrict,
  fromUnixTime,
} from 'date-fns';
import fileDownload from 'js-file-download';
import { CountryCode } from 'libphonenumber-js';
import {
  isEmpty,
  isEqual,
  isNull,
  isPlainObject,
  isUndefined,
  transform,
} from 'lodash';
import { ToWords } from 'to-words';
import * as Yup from 'yup';

import { BRASS_BANKING_APP_ANDROID, BRASS_BANKING_APP_IOS } from 'src/data/env';

import { Account } from 'src/types';
import { File, IObject } from 'src/types/generics';

export const truncate = (text = '', length: number) =>
  text
    ? text.length <= length
      ? text
      : `${text.substring(0, length)}...`
    : '';

export const capitalize = (text = '') => {
  return text
    ? text
        .split(' ')
        .map(
          item =>
            `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`,
        )
        .join(' ')
    : '';
};

export const getDaysFromNow = (unix: number) => {
  const now = new Date();
  const dateToCompare = fromUnixTime(unix);
  return differenceInHours(dateToCompare, now) / 24;
};

export interface CurrentDate {
  month: string;
  year: string;
  day: string;
}

export const createDateString = ({ month, year, day }: CurrentDate) => {
  return `${year}-${month}-${day}`;
};

export const downloadFile = async ({ url, key = '', content_type }: File) => {
  const data = await fetch(url).then(res => res.blob());
  const filename = [...key.split('/')].pop() || '';
  fileDownload(data, filename, content_type);
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export const convertNumberToWords = (number: number) => {
  const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: false,
      ignoreDecimal: true,
    },
  });
  return toWords.convert(number);
};

const getShort = (value: number, exp: number, char: string) => {
  return parseFloat((value / Math.pow(10, exp)).toFixed(1)).toString() + char;
};

export const shortenAmount = (value: number) => {
  const absValue = Math.abs(value);
  let a = '';

  if (absValue >= Math.pow(10, 12)) {
    a = getShort(value, 12, 'T');
  } else if (absValue >= Math.pow(10, 9)) {
    a = getShort(value, 9, 'B');
  } else if (absValue >= Math.pow(10, 6)) {
    a = getShort(value, 6, 'M');
  } else if (absValue >= Math.pow(10, 3)) {
    a = getShort(value, 3, 'K');
  }

  return a;
};

export const copyText = ({
  text,
  successMessage = 'Copied to clipboard',
  errorMessage = 'Oops!! Unable to copy to clipboard',
}: {
  text: string;
  successMessage?: string;
  errorMessage?: string;
}) => {
  try {
    if (!!navigator.clipboard && !!navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text);
    }

    notifySuccess(successMessage);
  } catch (error) {
    notifyError(errorMessage);
  }
};

export function clean<T = any>(object: T) {
  return transform<any, T>(object, (result, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = clean(value);
    }

    // Exclude empty objects.
    if (isPlainObject(value) && isEmpty(value)) {
      return;
    }

    // Exclude empty arrays.
    if (Array.isArray(value) && !value.length) {
      return;
    }

    // Exclude empty strings.
    if (value === '') {
      return;
    }

    // Exclude NaN values.
    if (Number.isNaN(value)) {
      return;
    }

    // Exclude null values.
    if (value === null) {
      return;
    }

    // Exclude undefined values.
    if (value === undefined) {
      return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }

    result[key as keyof typeof result] = value;
  });
}

export const convertImageToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve({ imageURL: e?.target?.result });
      };
      reader.onerror = function () {
        reject(new Error('Image conversion failed'));
      };
      reader.readAsDataURL(file);
    } else {
      reject(new Error('Image is empty'));
    }
  });
};

export function objectDifference<T extends Object = IObject>(
  oldObj: T,
  newObj: T,
) {
  return Object.keys(oldObj).reduce((acc: T, key: string): T => {
    const newValue = newObj[key as keyof typeof newObj];
    const oldValue = oldObj[key as keyof typeof oldObj];
    if (
      !isUndefined(newValue) &&
      !isNull(newValue) &&
      !isEqual(newValue, oldValue)
    ) {
      acc[key as keyof typeof acc] = newValue;
    }
    return acc;
  }, {} as T);
}

export const validatePhoneNumber = (
  errorMessage: string,
  countryCode?:
    | CountryCode
    | {
        defaultCountry?: CountryCode | undefined;
        defaultCallingCode?: string | undefined;
      }
    | undefined,
) => {
  let isValidPhoneNumber: (typeof import('libphonenumber-js/min'))['isValidPhoneNumber'];
  (async () =>
    await import(
      /* webpackChunkName: "libphonenumber-js" */ 'libphonenumber-js/min'
    ).then(module => {
      isValidPhoneNumber = module.isValidPhoneNumber;
    }))();

  return Yup.string().test(
    'is-phone-number-valid',
    errorMessage,
    (value?: string | null) => {
      if (!value) return false;
      return isValidPhoneNumber && isValidPhoneNumber(value, countryCode);
    },
  );
};

export const getFilenameFromUrl = (url: string) => {
  if (!url) return '';
  return url?.split('/')?.splice(-2, 2)?.join('/') || '';
};

export const paramsToObject = (entries: any) => {
  let result: any = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key as keyof typeof result] = value;
  }
  return result;
};

export const generateCookieExpiry = (expiryInMins: number) =>
  1 / ((60 * 24) / expiryInMins);

export const getDueDateInWords = (due_at: string) =>
  formatDistanceToNowStrict(new Date(due_at), {
    addSuffix: true,
    roundingMethod: 'floor',
  }).toUpperCase();

export const removeItemByPropertyKey = <T>(
  key: string,
  arr: T[],
  items: T[],
) => {
  const itemsPropertyValue = items?.map(item => item[key as keyof typeof item]);
  return arr?.filter(
    item => !itemsPropertyValue.includes(item[key as keyof typeof item]),
  );
};

export const getSleepScoreRadialColor = (score: number) => {
  if (score < 70) {
    return '#F21414';
  }
  if (score < 80) {
    return '#F8EBAD';
  }
  return '#265AEA';
};

export const getAppStoreLink = () => {
  let appStoreLink = BRASS_BANKING_APP_ANDROID;
  if (
    navigator.userAgent.indexOf('Mac') !== -1 ||
    navigator.userAgent.indexOf('iPhone') !== -1
  )
    appStoreLink = BRASS_BANKING_APP_IOS;

  return appStoreLink;
};

export const setRequestHeaders = (
  req: InternalAxiosRequestConfig,
  headers: IObject,
): InternalAxiosRequestConfig => ({
  ...req,
  headers: { ...req?.headers, ...headers } as AxiosRequestHeaders,
});

export const getAccountAlias = (data: Account, businessName = '') => {
  const { is_default, name } = data;
  let alias = data.alias;

  if (is_default) alias = 'Brass main account';

  const aliasFromName = name.slice(businessName.length + 3);

  if (!alias) alias = aliasFromName || name;

  return alias;
};

export const getCauserData = (
  trx: any,
): { name: string; bank: string; accountNumber: string } => {
  const { causer_type, causer } = trx;
  const info = {
    name: causer?.data?.name || '',
    bank: causer?.data?.bank?.data?.name || '',
    accountNumber: causer?.data?.number || '',
  };
  const data = (trx as any)?.counterparties?.data[0]?.counterparty?.data;
  switch (causer_type) {
    case 'disbursement':
      info.name =
        causer?.data?.counterparty?.data?.counterparty?.data?.name || '';
      info.accountNumber =
        causer?.data?.counterparty?.data?.counterparty?.data?.number || '';
      info.bank =
        causer?.data?.counterparty?.data?.counterparty?.data?.bank?.data
          ?.name || '';
      return info;
    case 'counterparty':
      info.name = causer?.data?.counterparty?.data?.name || '';
      info.accountNumber = causer?.data?.counterparty?.data?.number || '';
      info.bank = causer?.data?.counterparty?.data?.bank?.data?.name || '';
      return info;
    case 'user':
      info.name = `${causer?.data?.firstname || ''} ${
        causer?.data?.lastname || ''
      }`;
      return info;
    case 'cash_inflow':
      info.name = causer?.data?.payer?.data?.name;
      info.bank = causer?.data?.payer?.data?.bank
        ? causer.data.payer.data.bank.data.name
        : '';
      info.accountNumber = causer?.data?.payer?.data?.number || '';
      return info;
    case 'terminal_settlement':
      info.name =
        `Terminal ${causer?.data?.terminal?.data?.serial_number}` || '';
      return info;
    case null:
    case undefined:
      if (!(trx as any).counterparties?.data[0]) {
        return info;
      }
      info.name = data?.name || '';
      info.bank = data?.bank?.data?.name || '';
      info.accountNumber = data?.number || '';
      return info;
    default:
      return info;
  }
};

export const emitError = (err: Error) => {
  notifyError(err.message);
  // Report error to sentry
  SentryCaptureException(err);
};
